import React from 'react';
import tw, { styled } from 'twin.macro'; // eslint-disable-line import/no-extraneous-dependencies
// import { StaticImage } from 'gatsby-plugin-image';
import logo from '../assets/logo.png';

export const LogoImg = styled('img')(() => [tw``]);
export const LogoContainer = styled.div(() => [tw`w-3/5`]);

interface LogoProps extends HTMLDivElement {
  dark?: boolean;
}

function Icon() {
  return (
    <LogoContainer>
      <img
        src="./logo.png"
        alt="Tutadoo"
        placeholder="blurred"
        width={250}
        height={90}
        // layout="constrained"
      />
    </LogoContainer>
  );
}

export const Logo = styled(Icon)<LogoProps>(({ dark }) => [tw`my-4`]);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/jsx-props-no-spreading
export default Logo;
