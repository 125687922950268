import React from 'react';
import Col from 'components/Col';
import Row from 'components/Row';
import Text from 'components/Text';
import Logo from 'components/Logo';
import { FormHolder, BaseForm } from 'components/Form';
import Button, { ActionButton } from 'components/Button';

export default function Login({ onLogin, subtitle, loading, footer, footerLink }) {
  return (
    <FormHolder onSubmit={onLogin}>
      {({ methods }) => (
        <Col>
          <Col centered>
            <Col centered spacing="sm">
              <Logo />
            </Col>
            <Text label="Welcome" light medium spacing="sm" />
            <Text label={subtitle} small />
          </Col>
          <Col>
            <BaseForm
              pages={[
                {
                  direction: 'col',
                  elements: [
                    {
                      isRequired: true,
                      name: 'email',
                      placeholder: 'Email Address',
                      defaultValue: '',
                      label: 'Email',
                      full: true
                    },
                    {
                      isRequired: true,
                      name: 'password',
                      placeholder: 'Password',
                      defaultValue: '',
                      label: 'Password',
                      full: true
                    }
                  ]
                }
              ]}
              defaultValues={{}}
              state={{ data: {} }}
              methods={methods}
            />
            <ActionButton primary bold loading={loading}>
              <Text label="Continue" centered dark full />
            </ActionButton>
            <Col spacing="sm">
              <Row>
                <Text label={footer} small />{' '}
                <Button label={footerLink.label} to={footerLink.to} small />
              </Row>
            </Col>
          </Col>
        </Col>
      )}
    </FormHolder>
  );
}

Login.defaultProps = {
  omLoginn: () => {},
  loading: false,
  subtitle: 'Login to create or book an event',
  footer: "Don't have an account?",
  footerLink: {
    label: 'Signup',
    to: '/auth/signup'
  }
};
