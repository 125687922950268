import React from 'react';

// import { navigate } from 'gatsby';
import Col from 'components/Col';
import Login from 'components/Auth/Login';
import tw, { styled } from 'twin.macro';

const LoginContainer = styled(Col)(() => [
  tw`md:w-6/12 w-7/12 top-0 bottom-0 right-0 left-0 mx-auto shadow-2xl p-10 my-40 rounded-sm`
]);

function login() {
  //   useEffect(() => {
  //     navigate('/');
  //   }, []);
  const onLogin = () => {};
  return (
    <LoginContainer>
      <Login onLogin={onLogin} />
    </LoginContainer>
  );
}

export default login;
